$spacer: 0.5rem;

$font-size-base: 0.93755rem;
$h1-font-size: $font-size-base * 2.1;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;

$list-group-item-padding-y: 0.5rem;
$list-group-item-padding-x: 0.5rem;

$font-family-sans-serif: system-ui;

@import "~bootstrap/scss/bootstrap";

body {
  overflow-y: scroll;
}

.navbar form input {
  width: 8em;
  margin-top: 0.2rem;
  margin-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.w-third {
  width: 33% !important;
}
@media screen and (max-width: 575px) {
  .w-third {
    width: 66% !important;
  }
}

.table .progress-cell {
  min-width: 6rem;
  padding: 8px 1rem 10px 0.5rem;
  vertical-align: bottom;
}

.progress-cell .progress {
  height: 1em;
}

.container-fluid[role="main"] {
  padding-top: 1rem;
  padding-bottom: 5rem;
}

.input-group .short {
  max-width: 8em;
}

.tiny-icons {
  display: inline-block;
  font-size: 0.5em;
  vertical-align: top;
}

.btn {
  fill: currentColor;
}

.btn-secondary svg {
  fill: white;
}

small {
  font-size: 80%;
}

small > a.active {
  text-decoration: underline;
}

.table .table {
  background: transparent;
}

.table tbody th > a:not(.btn) /* override */,
.table tbody td > a:not(.btn) /* override */,
tbody th > a {
  display: block;
  margin: -8px;
  padding: 8px;
  text-decoration: none;
}

.table th.inline-links > a,
.table td.inline-links > a {
  display: inline-block !important;
}

.table .sticky {
  position: sticky;
  z-index: 1;
  top: 47px;
  background: white;
}

.inline {
  display: inline !important;
}

.invalid-feedback {
  display: block !important;
}

.form-search {
  align-items: flex-start;
  margin-bottom: 1rem;
}

.form-search input,
.form-search select {
  max-width: 10em;
}

.form-search .form-group {
  display: flex;
  align-items: center;
  gap: 4px;
}

.form-search > * {
  margin-left: 0.25rem;
}

.form-search label:empty {
  display: none;
}

.form-search .widget--checkboxinput {
  align-self: center;
}

.modal-footer .pagination {
  margin-bottom: 0;
}

.form-group.required > label {
  font-weight: bold;
}

.form-text {
  margin: 0;
}

.form-help-text {
  display: block;
  margin: -8px 0 4px;
  font-size: 85%;
  color: #443 !important;
}

.progress-line {
  height: 6px;
}

[data-field-value] {
  cursor: pointer;
}

.badge {
  align-self: flex-start;
  font-weight: normal;
}

.reduce-opacity {
  opacity: 0.7;
}

@media print {
  body {
    font-size: 13px;
  }

  .tiny-icons {
    display: none;
  }
}

.pie .pie-circle {
  fill: #325d88;
}

.pie .pie-arc {
  fill: #f47c3c;
}
.pie.good .pie-arc {
  fill: lightgreen;
}

.bg-caveat {
  background-color: #f1e53b;
}

.bg-break {
  background-color: lightskyblue;
}

.text-black-30,
.text-black-30 a {
  color: rgba(0, 0, 0, 0.3) !important;
}

.text-break {
  overflow-wrap: break-word;
}

.anchor-container {
  position: relative;
}

.anchor {
  position: relative;
  top: -80px;
}

.debug-is-active::after {
  content: "DEBUG";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  font-size: 10px;
  height: 15px;
  background: rgba(150, 0, 0, 0.8);
  color: white;
  text-align: center;
}

.uplink {
  margin-bottom: 0.5rem;
}

.table-offer-pricing tbody label {
  display: none;
}

.fixed-btn {
  position: fixed;
  bottom: 1.5rem;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  z-index: 1030;
  text-align: center;
  font-size: 1.8rem;
  box-shadow: 1px 3px 5px 0 rgba(0, 0, 50, 0.3);
  background-image: url("lib/stopwatch.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.fixed-btn:hover {
  opacity: 1;
}

.fixed-btn.break {
  /* bottom: 5rem; */
  left: 4.25rem;
  background-image: url("lib/break.svg");
}

.widget--checkboxselectmultiple .my-2 {
  column-width: 15rem;
}
.widget--checkboxselectmultiple .my-2 .form-check {
  margin: 0 0 0.25rem 0;
}
.widget--checkboxselectmultiple input:checked ~ label {
  font-weight: bold;
}

.dropdown-menu.show {
  z-index: 9999;
}

.contribution-selects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.widget--contributionselect {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 0;
}

.widget--contributionselect label {
  order: 1;
}

.contribution-select input {
  display: none;
}

.contribution-select label {
  cursor: pointer;
  font-size: 1.5em;
}

.contribution-select label:first-of-type {
  filter: hue-rotate(180deg) grayscale(0.3);
}

.contribution-select input:checked ~ label {
  filter: grayscale(1) brightness(1.1);
  transform: scale(0.85);
}

.pin {
  border: none;
  line-height: 1;
  background: none;
  font-size: 1rem;
  filter: grayscale(1);

  &[data-pinned="true"] {
    filter: none;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
